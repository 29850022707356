import React from "react";
import api from "../../services/axios-config";
import GitHubLogin from "react-github-login";
import { useNavigate } from "react-router-dom";
import { isAxiosError } from "axios";

const GitHubSignIn = () => {

  // const CLIENT_ID = process.env.REACT_APP_GITHUB_CLIENT_ID;
  const CLIENT_ID = 'd3574a70f71cdd955be0';
  const navigate = useNavigate();

  const sendCodeAndSignup = async (code) => {
    try {
      const { data } = await api.post('/auth/callback/github', code);
      if (data) localStorage.setItem("userData", JSON.stringify(data.user));
      navigate("/code");
    } catch (error) {
      if (isAxiosError(error) && error.response) {
      }
    }
  };


  const onSuccess = async (response) => {
    sendCodeAndSignup(response)
  };

  const onFailure = (response) => {
  };

  return (
    <div>
      <GitHubLogin
        className="w-100 px-2 btn btn-primary inner_button transparent_button"
        clientId={CLIENT_ID}
        onSuccess={onSuccess}
        onFailure={onFailure}
        // buttonText='Sign in with Github'
        buttonText={<p className="m-0 d-flex align-items-center justify-content-center gap-4"><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
          <g clip-path="url(#clip0_953_703)">
            <path d="M16.0005 31.9789C24.6415 31.9789 31.6465 24.8249 31.6465 16C31.6465 7.17509 24.6415 0.0210953 16.0005 0.0210953C7.35944 0.0210953 0.354492 7.17509 0.354492 16C0.354492 24.8249 7.35944 31.9789 16.0005 31.9789Z" fill="white" />
            <path d="M16.0006 0.0813446C7.16508 0.0813446 0 7.22666 0 16.0423C0 23.0953 4.58483 29.078 10.9417 31.188C11.7409 31.3354 12.0352 30.8419 12.0352 30.4201C12.0352 30.0394 12.0198 28.7818 12.0133 27.4487C7.56214 28.4139 6.62282 25.5655 6.62282 25.5655C5.89551 23.7209 4.84568 23.2299 4.84568 23.2299C3.39365 22.239 4.95491 22.2595 4.95491 22.2595C6.56114 22.3723 7.40794 23.9042 7.40794 23.9042C8.83556 26.3449 11.1511 25.6386 12.0634 25.231C12.2074 24.199 12.6211 23.4953 13.0799 23.0966C9.5256 22.6928 5.79015 21.3237 5.79015 15.2078C5.79015 13.4657 6.41465 12.0415 7.43878 10.9237C7.27302 10.5224 6.72562 8.89955 7.59427 6.70108C7.59427 6.70108 8.93836 6.27165 11.9953 8.33679C13.2713 7.98299 14.6398 7.80608 16.0006 7.79967C17.3602 7.80608 18.73 7.98299 20.0085 8.33679C23.0629 6.27165 24.4044 6.70108 24.4044 6.70108C25.2757 8.89955 24.727 10.5237 24.5612 10.9249C25.5866 12.0428 26.2073 13.467 26.2073 15.2091C26.2073 21.3391 22.4641 22.6902 18.9009 23.0851C19.4752 23.5799 19.9867 24.5516 19.9867 26.0411C19.9867 28.1768 19.9687 29.8958 19.9687 30.4214C19.9687 30.8457 20.2565 31.3444 21.0673 31.1867C27.4216 29.0741 32 23.0928 32 16.0423C32 7.22666 24.8362 0.0813446 16.0006 0.0813446Z" fill="#5C6BC0" />
          </g>
          <defs>
            <clipPath id="clip0_953_703">
              <rect width="32" height="32" fill="white" />
            </clipPath>
          </defs>
        </svg>Sign in with Github</p>
        }
        // redirectUri="http://localhost:3000/api/auth/callback/github"
        redirectUri="https://brainycode.software/api/auth/callback/github"
        scope="user"
      />
    </div>
  );
};

export default GitHubSignIn;