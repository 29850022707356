import React from 'react'
import BuyCredit from '../../component/credits/BuyCredit'

const BuyCreditPage = () => {
  return (
    <div>
      <BuyCredit/>
    </div>
  )
}

export default BuyCreditPage