import React from 'react'
import AdminPrompt from '../../component/admin/AdminPrompt'

const AdminPrompts = () => {
  return (
    <div>
        <AdminPrompt/>
    </div>
  )
}

export default AdminPrompts