import React from 'react'
import OrderConfirm from '../../component/credits/OrderConfirm'
const OrderConfirmPage = () => {
  return (
    <div>
      <OrderConfirm />
    </div>
  )
}

export default OrderConfirmPage
