import React, { useState } from 'react';

const CreateNewProject = ({ closeModal, handleNextStep, generateCode, fomData }) => {
  const [projectName, setProjectName] = useState('');
  const [error, setError] = useState('');
  const [createProjectLoad, setCreateProjectLoad] = useState(false);
  const [skipLoad, setSkipLoad] = useState(false);

  const handleOutsideClick = (e) => {
    if (e.target.id === 'exampleModal') {
      closeModal();
    }
  };

  const handleExistingProject = () => {
    handleNextStep();
  };

  const handleCreateProject = async () => {
    if (!projectName.trim()) {
      setError('Project title is required');
      return;
    }

    setCreateProjectLoad(true);

    try {
      const values = { projectName, ...fomData };
      await generateCode(values);
    } finally {
      setCreateProjectLoad(false);
    }
  };

  const handleSkip = async () => {
    setSkipLoad(true);

    try {
      await generateCode(fomData);
    } finally {
      setSkipLoad(false);
    }
  };

  return (
    <div className="modal generate_modal createNewModal show code_submission justify-content-center align-items-lg-center align-items-md-start align-items-start show"
      id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" onClick={handleOutsideClick}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-body p-0">
            <div className="inner_text tracking_form sign_up_img">
              <h2 className="text-center mb-lg-4 mb-md-3 mb-3 pb-lg-2"><b style={{ fontWeight: '700' }}>Create new project?</b></h2>
              <p className='text-center  mb-lg-4 mb-md-3 mb-3 pb-lg-2'>Your sessions can now be organized into distinct projects. This allows you to collectively submit an entire project for testing and review, enabling us to assess and provide feedback on all the sessions within that project comprehensively.</p>
              <div className='row justify-content-center  position-relative'>
                <div className='col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12'>
                  <form>
                    <div className='d-flex flex-column gap-4 mb-lg-4 mb-md-3 mb-3 pb-lg-2'>
                      <div>
                        <label htmlFor="project_input" className="form-label mb-2">Project title :</label>
                        <input type="text" className="form-control" id="project_input" placeholder="" value={projectName} onChange={(e) => setProjectName(e.target.value)} />
                        {error && <p className="text-danger">{error}</p>}
                      </div>
                    </div>
                    <div className="w-100 d-flex align-items-center flex-lg-row flex-md-row flex-column gap-3 justify-content-center">
                      <button type='button'
                        onClick={handleExistingProject}
                        className="btn w-100 py-3 px-lg-4 text-nowrap px-3 btn-outline-primary">
                        Add to existing project
                      </button>
                      <button type='button'
                        onClick={handleCreateProject}
                        className="btn w-100 py-3 px-lg-4 text-nowrap px-3 btn-primary">
                        {createProjectLoad ? (
                          <div className="w-100 align-items-center d-flex justify-content-center">
                            <h1 className="loading-spinner" style={{ color: "#101A36", margin: "0" }}></h1>
                          </div>
                        ) : (
                          "Create Project"
                        )}
                      </button>
                      <div className='m-0 px-4 py-3 inner_text skip_button'>
                        <p className='m-0' style={{cursor:"pointer"}}>
                          {skipLoad ? (
                            <div className="w-100 align-items-center d-flex justify-content-center">
                              <h1 className="loading-spinner" style={{ color: "#101A36", margin: "0" }}></h1>
                            </div>
                          ) : (
                            <b onClick={handleSkip}>Skip</b>
                          )}
                        </p>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateNewProject;
