import React, { useState, useEffect } from 'react'
import api from "../../services/axios-config";
import { isAxiosError } from "axios";
import moment from "moment";
import Modal from 'react-modal';

const AdminPromptDetails = ({ handleNextStep, closeModal, selectedRow }) => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const [load, setLoad] = useState(false);

    const handleSubmitResponse = () => {
        handleNextStep();
    }

    const submitDownload = async () => {
        setLoad(true)
        try {
            const requestData = {
                userId: selectedRow.userId
            };

            if (selectedRow.PromptReview && selectedRow.PromptReview.fileName) {
                requestData.fileName = selectedRow.PromptReview.fileName;
            }

            const response = await api.post(
                `/code/admin-download-prompt-review?userId=${userData.id}`,
                // { userId: selectedRow.userId, fileName: selectedRow.PromptReview?.fileName }
                requestData
            );
            const downloadUrl = response.data.file;
            const downloadLink = document.createElement('a');
            downloadLink.href = downloadUrl;
            downloadLink.download = 'file.zip';
            downloadLink.click();
        } catch (error) {
            if (isAxiosError(error) && error.response) {
            }
        } finally {
            setLoad(false)
        }
    };

    return (
        <>
            {selectedRow ? (
                <div className="modal generate_modal show code_submission align-items-xl-start align-items-lg-start align-items-md-start align-items-start  fade"
                    id="exampleModal" tabindex="-1"
                    aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content p-lg-4 p-md-3 p-3">

                            <div className=" d-flex align-items-center">

                                <div className="inner_text sign_up_img">

                                    <h5 className="text-center m-0"><b>User
                                        Details</b></h5>

                                </div>

                                <button type="button" className="button_close"
                                    data-bs-dismiss="modal" aria-label="Close">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="41"
                                        height="40" viewBox="0 0 41 40" fill="none" onClick={closeModal}>
                                        <path
                                            d="M22.8502 20.0001L33.3502 9.51673C33.6641 9.20289 33.8404 8.77723 33.8404 8.3334C33.8404 7.88956 33.6641 7.4639 33.3502 7.15006C33.0364 6.83623 32.6107 6.65991 32.1669 6.65991C31.7231 6.65991 31.2974 6.83623 30.9836 7.15006L20.5002 17.6501L10.0169 7.15006C9.70305 6.83623 9.27739 6.65991 8.83356 6.65991C8.38972 6.65991 7.96406 6.83623 7.65022 7.15006C7.33638 7.4639 7.16007 7.88956 7.16007 8.3334C7.16007 8.77723 7.33638 9.20289 7.65022 9.51673L18.1502 20.0001L7.65022 30.4834C7.49401 30.6383 7.37002 30.8227 7.28541 31.0258C7.20079 31.2289 7.15723 31.4467 7.15723 31.6667C7.15723 31.8867 7.20079 32.1046 7.28541 32.3077C7.37002 32.5108 7.49401 32.6951 7.65022 32.8501C7.80516 33.0063 7.9895 33.1303 8.1926 33.2149C8.39569 33.2995 8.61354 33.3431 8.83356 33.3431C9.05358 33.3431 9.27142 33.2995 9.47452 33.2149C9.67762 33.1303 9.86195 33.0063 10.0169 32.8501L20.5002 22.3501L30.9836 32.8501C31.1385 33.0063 31.3228 33.1303 31.5259 33.2149C31.729 33.2995 31.9469 33.3431 32.1669 33.3431C32.3869 33.3431 32.6048 33.2995 32.8079 33.2149C33.011 33.1303 33.1953 33.0063 33.3502 32.8501C33.5064 32.6951 33.6304 32.5108 33.715 32.3077C33.7997 32.1046 33.8432 31.8867 33.8432 31.6667C33.8432 31.4467 33.7997 31.2289 33.715 31.0258C33.6304 30.8227 33.5064 30.6383 33.3502 30.4834L22.8502 20.0001Z"
                                            fill="#271353" />
                                    </svg>
                                </button>
                            </div>

                            <div className="modal-body p-0 pt-4">
                                <div
                                    className="overview_profile d-lg-flex d-md-flex d-block align-items-center">
                                    <div className="profile_img">
                                        {selectedRow && selectedRow.profileUrl ? (
                                            <img src={selectedRow.profileUrl} style={{ width: '130px', height: '130px' }} />
                                        ) : (
                                            <img src="images/blank-profile-picture.jpg" style={{ width: '130px', height: '130px' }} />
                                        )}
                                    </div>
                                    <div
                                        className="profile_detail sign_up_img inner_text ms-lg-3 ps-lg-1 ms-md-3 ps-md-1 mt-lg-0 mt-md-0 mt-4">
                                        <p className="pb-1 mb-0"
                                            style={{ color: "#FF13E5" }}><b>User ID:
                                            </b>{selectedRow.userId}</p>
                                        <h5 className="mb-0"> {selectedRow.firstName} {selectedRow.lastName}</h5>
                                        <p className="mt-2 mb-0"><b>Email: </b>{selectedRow.email}</p>
                                        <div
                                            className="d-flex align-items-center gap-4">
                                            <p className="mt-2 mb-0"><b>Gender:</b>
                                                {selectedRow.gender}</p>
                                            <p className="mt-2 mb-0"><b>Age:</b> {selectedRow.age}</p>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- <div>
                        <div
                            className="profile_detail sign_up_img inner_text d-flex align-items-center gap-4">
                        </div>
                    </div> --> */}
                                <div className="col-12 my-3">
                                    <hr className="m-0" />
                                </div>
                                <div className="row request_table">
                                    <div className="col-12 mb-4">
                                        <div className="inner_text sign_up_img">
                                            <h5 className="text-start m-0">Subscription
                                                Details</h5>

                                        </div>
                                    </div>
                                    <div className="col-12 overflow-auto">

                                        <table className="table mb-0">
                                            <thead>
                                                <tr>
                                                    <th
                                                        className="text-nowrap text-start pb-3">Subscription
                                                        ID</th>
                                                    <th
                                                        className="text-nowrap text-center pb-3">Credit</th>
                                                    <th
                                                        className="text-nowrap text-center pb-3">Title</th>
                                                    <th
                                                        className="text-nowrap text-end pb-3">Subscription
                                                        Type</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {selectedRow.PromptReview ? (
                                                    <tr>
                                                        <td
                                                            className="text-start pt-3 pb-0"><b>{selectedRow.PromptReview.subscriptionId}</b></td>
                                                        <td
                                                            className="text-center pt-3 pb-0">{selectedRow.PromptReview.subscription.credit}</td>
                                                        <td
                                                            className="text-center pt-3 pb-0">{selectedRow.PromptReview.subscription.title}</td>
                                                        <td
                                                            className="text-end pt-3 pb-0"
                                                            style={{ color: '#FF13E5' }}><b>{selectedRow.PromptReview.subscription.subscriptionType}</b></td>
                                                    </tr>
                                                ) : (
                                                    'No data found'
                                                )}
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                                <div className="col-12 my-3">
                                    <hr className="m-0" />
                                </div>
                                <div className="row request_table">
                                    <div className="col-12 mb-4">
                                        <div className="inner_text sign_up_img">
                                            <h5 className="text-start m-0">Language
                                                Details</h5>

                                        </div>
                                    </div>
                                    <div className="col-12  overflow-auto">
                                        <table className="table mb-0">
                                            <thead>
                                                <tr>
                                                    <th
                                                        className="text-nowrap text-start pb-3">Language
                                                        ID</th>
                                                    <th
                                                        className="text-nowrap text-start pb-3">Language</th>
                                                    <th
                                                        className="text-nowrap text-start pb-3">Language
                                                        Framework</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td
                                                        className="text-start pt-3 pb-0"><b>{selectedRow.languageId}</b></td>
                                                    <td
                                                        className="text-start pt-3 pb-0">{selectedRow.language}</td>
                                                    <td
                                                        className="text-start pt-3 pb-0">{selectedRow.framework}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-12 my-3">
                                    <hr className="m-0" />
                                </div>
                                <div className="row request_table">
                                    <div className="col-12 mb-4">
                                        <div className="inner_text sign_up_img">
                                            <h5 className="text-start m-0">Propmt
                                                Details</h5>

                                        </div>
                                    </div>
                                    <div className="col-12 overflow-auto">
                                        <table className="table mb-0">
                                            <thead>
                                                <tr>
                                                    <th
                                                        className="text-nowrap text-start pb-3">Prompt
                                                        Id</th>
                                                    <th
                                                        className="text-nowrap text-center pb-3">Status</th>
                                                    <th
                                                        className="text-nowrap text-end pb-3">Created
                                                        Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {/* {selectedRow.PromptReview ? (
                                            <tr>
                                                <td
                                                    className="text-start pt-3 pb-0"><b>{selectedRow.PromptReview.promptId}</b></td>
                                                <td
                                                    className="text-center pt-3 pb-0 text-success"><b>{selectedRow.PromptReview.status}</b></td>
                                                <td
                                                    className="text-end pt-3 pb-0">{moment(selectedRow.PromptReview.createdAt).format('MMMM DD YYYY')}</td>
                                            </tr>
                                        ) : (
                                            'No data found'
                                        )} */}
                                                {selectedRow.PromptReview ? (
                                                    <tr>
                                                        <td className="text-start pt-3 pb-0"><b>{selectedRow.PromptReview.promptId}</b></td>
                                                        <td className={`text-center pt-3 pb-0 ${selectedRow.PromptReview.status === 'Reviewed' ? 'text-success' : 'text-danger'}`}>
                                                            <b>{selectedRow.PromptReview.status}</b>
                                                        </td>
                                                        <td className="text-end pt-3 pb-0">
                                                            {moment(selectedRow.PromptReview.createdAt).format('MMMM DD YYYY')}
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    <tr>
                                                        <td className="text-start pt-3 pb-0" colSpan={3}>No data found</td>
                                                    </tr>
                                                )}

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div
                                    className="btn_group d-flex align-items-center gap-3 mt-4 justify-content-center flex-lg-row flex-md-row flex-column">
                                    {selectedRow.PromptReview && selectedRow.PromptReview ? (
                                        <button
                                            className="btn btn-primary w-100 justify-content-center" onClick={handleSubmitResponse}>
                                            Submit Response</button>
                                    ) : null}
                                    
                                    {selectedRow.PromptReview && selectedRow.PromptReview.fileName ? (
                                        <button
                                            className="btn btn-primary w-100 justify-content-center"
                                            onClick={submitDownload}
                                        >
                                            {load ? (
                                                <div className="w-100 align-items-center d-flex justify-content-center">
                                                    <h1
                                                        className="loading-spinner"
                                                        style={{
                                                            color: "#101A36",
                                                            margin: "0",
                                                        }}
                                                    ></h1>
                                                </div>
                                            ) : (
                                                "Download"
                                            )}
                                        </button>
                                    ) : (
                                        <button
                                            className="btn btn-primary w-100 justify-content-center"
                                            disabled
                                        >
                                            Download
                                        </button>
                                    )}
                                    <button
                                        className="btn btn-outline-primary w-100 justify-content-center" onClick={closeModal}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            ) : (
                <div>No data found</div>
            )}
        </>
    )
}

export default AdminPromptDetails