import React, { useState, useEffect } from 'react';
import Editor, { useMonaco } from '@monaco-editor/react';

const CodeViewer = ({ code, language, streaming }) => {
    const [editorRef, setEditorRef] = useState(null);
    const [isAtEnd, setIsAtEnd] = useState(false);
    const monaco = useMonaco();
    if (monaco) {
        monaco.editor.setTheme('vs-dark');
    }


    const handleEditorMount = (editor, monaco) => {
        setEditorRef(editor);

        editor.onDidScrollChange((e) => {
            const threshold = 100; // Pixels from the bottom to consider "near the end"
            // Use the editor's DOM element to find the visible height
            const editorDomNode = editor.getDomNode();
            if (!editorDomNode) return; // Guard to ensure the DOM node is available

            const visibleHeight = editorDomNode.clientHeight;

            // Calculate the distance from the bottom
            const distanceFromBottom = e.scrollHeight - (e.scrollTop + visibleHeight);

            if (distanceFromBottom > threshold) {
                // The scroll is not near the end
                setIsAtEnd(true)
            } else {
                setIsAtEnd(false)
            }
        });
    };

    useEffect(() => {
        if (editorRef && code && streaming && !isAtEnd) {
            const model = editorRef.getModel();
            const lineCount = model.getLineCount();
            editorRef.revealLine(lineCount);
        }
    }, [code, editorRef, streaming, isAtEnd]);

    const getLanguageId = (language) => {
        switch (language.toLowerCase()) {
            case 'javascript':
            case 'react':
                return 'javascript';
            case 'python':
            case 'django':
            case 'flask':
            case 'pyramid':
            case 'fastapi':
                return 'python';
            case 'c++':
                return 'cpp';
            case 'rust':
                return 'rust';
            default:
                return 'plaintext';
        }
    };
    

    return (
        <Editor
            height="500px"
            width="1000px"
            defaultLanguage="javascript"
            language={getLanguageId(language)}
            value={code}
            onMount={handleEditorMount}
            options={{
                readOnly: true,
                lineNumbers: "on",
                minimap: { enabled: false },
                wordWrap: 'on',
                scrollBeyondLastLine: false,
            }}
        />
    );
};

export default CodeViewer;