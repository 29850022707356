
import React, { useState, useEffect } from 'react';
import api from "../../services/axios-config";

const SelectProject = ({ closeModal, generateCode, fomData, goToPreviousStep }) => {
  const userData = JSON.parse(localStorage.getItem("userData"));

  const [projects, setProjects] = useState([]);
  const [error, setError] = useState(null); // Changed to null initially
  const [projectId, setProjectId] = useState("");
  const [createProjectLoad, setCreateProjectLoad] = useState(false);
  const [skipLoad, setSkipLoad] = useState(false);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    getProjects();
  }, []);

  const getProjects = async () => {
    setLoad(true);
    try {
      const response = await api.get(`/code/projects?userId=${userData.id}`);
      const data = response.data.projects;
      if (data.length === 0) {
        setError("No projects are available"); // Set error message when no projects are available
      } else {
        setProjects(data);
      }
    } catch (error) {
      console.error(error);
    }
    setLoad(false);
  };

  const handleSkip = async () => {
    setSkipLoad(true);
    try {
      await generateCode(fomData);
    } finally {
      setSkipLoad(false);
    }
  };

  const handleCreateProject = async () => {
    const values = {
      projectId: projectId,
      ...fomData
    };

    setCreateProjectLoad(true);

    try {
      const values = { projectId, ...fomData };
      await generateCode(values);
    } finally {
      setCreateProjectLoad(false);
    }
  };

  const handleProjectSelection = (selectedProjectId) => {
    // Check if the clicked project is already selected
    const isSelected = projects.find(project => project.id === selectedProjectId)?.selected;
  
    // Deselect all projects
    const updatedProjects = projects.map(project => ({
      ...project,
      selected: false
    }));
  
    // Toggle the selection of the clicked project
    const updatedProjectsAfterSelection = updatedProjects.map(project => {
      if (project.id === selectedProjectId) {
        return { ...project, selected: !isSelected };
      }
      return project;
    });
  
    setProjects(updatedProjectsAfterSelection);
  
    // Update the projectId state based on the selection
    if (!isSelected) {
      setProjectId(selectedProjectId);
    } else {
      setProjectId("");
    }
  };
  
  
  

  const handleOutsideClick = (e) => {
    if (e.target.id === 'exampleModal') {
      closeModal();
    }
  };

  return (
    <>
      <div>
        <div className="modal generate_modal createNewModal show code_submission justify-content-center align-items-lg-center align-items-md-start align-items-start show"
          id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" onClick={handleOutsideClick}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body p-0">
                <div className="inner_text tracking_form sign_up_img">
                  <h2 className="text-center mb-lg-4 mb-md-3 mb-3 pb-lg-2"><b style={{ fontWeight: '700' }}>Select project</b></h2>
                  {error && (
                    <p className="text-center mb-lg-4 mb-md-3 mb-3 pb-lg-2" style={{ color: "red" }}>{error}</p>
                  )}
                  {!error && (
                    <p className='text-center mb-lg-4 mb-md-3 mb-3 pb-lg-2'>Select an existing project to add this session to. You can add more sessions later.</p>
                  )}
                  <div className='row justify-content-center position-relative'>
                    <div className='col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12'>
                      <form>
                        <div className='d-flex flex-column mb-lg-4 mb-md-3 mb-3 pb-lg-2'>
                          <div>
                            {!error && (
                              <label htmlFor="project_input" className="form-label mb-2">Project title :</label>
                            )}

                            {load ? (
                              <div className="w-100 page_loader align-items-center d-flex justify-content-center">
                                <h1
                                  className="loading-spinner"
                                  style={{
                                    color: "#101A36",
                                    margin: "0",
                                    backdropFilter: "blur(8px)",
                                  }}
                                ></h1>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className='select_project d-flex gap-2 flex-column'>
                            {projects.map(project => (
                              <div key={project.id} className='position-relative select_project_card_outer'>
                                <input type='checkbox' id={project.id} onChange={() => handleProjectSelection(project.id)} checked={project.selected} />
                                <div className='project_card'>
                                  <svg xmlns="http://www.w3.org/2000/svg" className='me-2' width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M15.5999 9.5999L10.2303 14.3999L8.3999 12.7637M21.5999 5.9999L21.5999 18C21.5999 19.9882 19.9881 21.6 17.9999 21.6H5.9999C4.01168 21.6 2.3999 19.9882 2.3999 18V5.9999C2.3999 4.01168 4.01168 2.3999 5.9999 2.3999H17.9999C19.9881 2.3999 21.5999 4.01168 21.5999 5.9999Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                  </svg>
                                  {project.projectName}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="w-100 d-flex align-items-center flex-lg-row flex-md-row flex-column gap-3 justify-content-center">
                          <button type='button' className="btn w-auto py-3 px-lg-4 text-nowrap px-3 btn-outline-primary" onClick={goToPreviousStep}>
                            Previous
                          </button>
                          <button type='button' className="btn w-auto py-3 px-lg-4 text-nowrap px-3 btn-primary"
                            onClick={handleCreateProject}
                            disabled={error || !projectId}>
                            {createProjectLoad ? (
                              <div className="w-100 align-items-center d-flex justify-content-center">
                                <h1
                                  className="loading-spinner"
                                  style={{
                                    color: "#101A36",
                                    margin: "0",
                                  }}
                                ></h1>
                              </div>
                            ) : (
                              " Select this project"
                            )}
                          </button>
                          <div className='m-0 px-4 py-3 inner_text skip_button'>
                            <p className='m-0' style={{ cursor: "pointer" }}>
                              {skipLoad ? (
                                <div className="w-100 align-items-center d-flex justify-content-center">
                                  <h1 className="loading-spinner" style={{ color: "#101A36", margin: "0" }}></h1>
                                </div>
                              ) : (
                                <b onClick={handleSkip}>Skip</b>
                              )}
                            </p>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SelectProject;

