import React, { useState, useEffect } from 'react'
import ReactTable from './ReactTable'
import moment from "moment";
import Modal from 'react-modal';
import api from "../../services/axios-config";
import AdminPromptDetails from '../model/AdminPromptDetails';
import UploadFile from '../model/UploadFile';

const AdminPrompt = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [load, setLoad] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [uploadError, setUploadError] = useState("");
    const [descriptionError, setDescriptionError] = useState("");

    const [modelStep, setModelStep] = useState(1);
    const [uploadReviewForm, setUploadReviewForm] = useState({
        file: null,
        description: ''
    });

    const userData = JSON.parse(localStorage.getItem("userData"));

    const openModal = (item) => {
        setSelectedRow(item);
        setModelStep(1);
        setModalIsOpen(true);
        setUploadError(null)
        setDescriptionError(null)
    }

    const closeModal = (inputColumn) => {
        setModalIsOpen(false);
        setModelStep(1);
    }

    const handleNextStep = () => {
        setModelStep(2);
    }

    useEffect(() => {
        getPromptData()
    }, []);

    const getPromptData = async () => {  
        setLoad(true)
        try {
            const response = await api.get(
                `/code/admin-prompts?userId=${userData.id}`
            );
            const formattedData = response.data.prompts.map((item) => ({
                id: item.id,
                prompt: item.prompt,
                language: item.languageDetails.language,
                framework: item.languageDetails.framework,
                createdAt: moment(item.createdAt).format('MMMM DD YYYY'),
                userId: item.userId,
                languageId: item.languageId,
                firstName: item.user.firstName,
                lastName: item.user.lastName,
                age: item.user.age,
                gender: item.user.gender,
                email: item.user.email,
                PromptReview: item.PromptReviews[0] ? item.PromptReviews[0] : null,
                profileUrl: item.user.profileUrl,
            }))
            setTableData(formattedData)
        } catch (error) {
            // toast.error(error);
        }
        setLoad(false)
    };

    const handleUploadAndReview = async (e, withoutFile = false) => {
        e.preventDefault();
        setLoad(true);
        try {
            const promptId = selectedRow.PromptReview.id;
            const reviewPlan = {
                promptReviewId: promptId,
                description: uploadReviewForm.description,
                userId: selectedRow.userId
            }

            //   let requestBody = {
            //     reviewPlan: reviewPlan
            //   };

            if (uploadReviewForm.file && !withoutFile) {
                // setLoad(true);

                const reader = new FileReader();
                reader.onload = () => {
                    const base64File = btoa(reader.result);
                    reviewPlan.file = base64File;
                    reviewPlan.fileName = uploadReviewForm.file.name;
                    reviewPlan.fileType = uploadReviewForm.file.type;
                    sendRequest(reviewPlan);
                };
                reader.readAsBinaryString(uploadReviewForm.file);
            } else {
                sendRequest(reviewPlan);
            }
        } catch (error) {
            // toast.error(error.response?.data?.message);

        }
        finally {
            setLoad(false);
        }
    };

    const sendRequest = async (reviewPlan) => {
        setUploadError(null)
        setDescriptionError(null)
        setLoad(true);
        if (!reviewPlan.fileName || !reviewPlan.description.trim()) {
            setUploadError(reviewPlan.fileName ? "" : "Upload File is required!");
            setDescriptionError(reviewPlan.description.trim() ? "" : "Description is required!");
            setLoad(false);
            return;
        }
        try {
            const response = await api.post(`/subscription/send-review-mail`, reviewPlan, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const data = response.data;
            if (!data) {
                // toast.error('Failed to review prompt')
            }
            setUploadReviewForm({
                file: null,
                description: '',
                setDescriptionError: ""

            })
        } catch (error) {
            // toast.error(error.response?.data?.message);
        }
        finally {
            setLoad(false);
            closeModal()
            getPromptData()
        }
    };
    const columns = [
        {
            Header: 'Prompt ID',
            accessor: 'id',
        },
        {
            Header: 'Prompts',
            accessor: 'prompt',
        },
        {
            Header: 'Language',
            accessor: 'language',
        },
        {
            Header: 'Framework',
            accessor: 'framework',
        },
        {
            Header: 'CreatedAt',
            accessor: 'createdAt',
        },
        {
            Header: 'View',
            accessor: 'View',
            Cell: ({ row }) => (
                <svg xmlns="http://www.w3.org/2000/svg"
                    onClick={() => openModal(row.original)} width="20" height="20" fill="#FF13E5" className="bi bi-eye" viewBox="0 0 16 16">
                    <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                    <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                </svg>
            ),
        },
    ];
    return (
        <div>
            {load ? (
                <div className="w-100 page_loader align-items-center d-flex justify-content-center">
                    <h1
                        className="loading-spinner"
                        style={{
                            color: "#101A36",
                            margin: "0",
                            backdropFilter: "blur(8px)",
                        }}
                    ></h1>
                </div>
            ) : (
                tableData.length > 0 ? (
                    <ReactTable columns={columns} data={tableData} />
                ) : (
                    <p>No data available</p>
                )
            )}
            <Modal
                isOpen={modalIsOpen}
                // style={customStyles}
                className='login_modal'
            >
                {modelStep == 1 && selectedRow && (<AdminPromptDetails closeModal={closeModal} selectedRow={selectedRow} handleNextStep={handleNextStep} />
                )}
                {modelStep == 2 && (<UploadFile
                    handleUploadAndReview={handleUploadAndReview}
                    setUploadReviewForm={setUploadReviewForm}
                    closeModal={closeModal}
                    openModal={openModal}
                    load={load}
                    uploadError={uploadError}
                    descriptionError={descriptionError}
                    setDescriptionError={setDescriptionError}
                    setUploadError={setUploadError}
                    type="adminprompt"
                />
                )}
            </Modal>
        </div>
    )
}

export default AdminPrompt