import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const ProtectedRoute = (props) => {
    const { Component, allowWithoutAuth, userGuard, role } = props;
    const navigate = useNavigate();
    useEffect(() => {

        if (!allowWithoutAuth) {
            const userData = localStorage.getItem('userData');
            if (!userData) {
                navigate('/signin');
            }
        }
        let userData;
        if (localStorage.getItem("userData")) {
            userData = JSON.parse(localStorage.getItem("userData"));
        }

        if (userGuard && userData?.role != "admin") {
            navigate('/');
        }
    }, [allowWithoutAuth, navigate]);

    return (
        <> <Component /> </>
    );
};

export default ProtectedRoute;

