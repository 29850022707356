// import React from 'react'
// import { useTable } from 'react-table';

// const ReactTable = ({ columns, data }) => {
//     const {
//         getTableProps,
//         getTableBodyProps,
//         headerGroups,
//         rows,
//         prepareRow,
//     } = useTable({
//         columns,
//         data,
//     });
//     return (

//         <section className="generate_code_section align-items-start">
//             <div className="container">
//                 <div className="row justify-content-center">
//                     <div className="col-lg-12 col-md-12 col-sm-12 col-12">
//                         <div className='request_table prompt_table overflow-auto'>
//                             <table className='table' {...getTableProps()}
//                             // style={{ border: 'solid 1px blue', width: '100%' }}
//                             >
//                                 <thead>
//                                     {headerGroups.map(headerGroup => (
//                                         <tr {...headerGroup.getHeaderGroupProps()}>
//                                             {headerGroup.headers.map(column => (
//                                                 <th {...column.getHeaderProps()}
//                                                 //  style={{ borderBottom: 'solid 1px red', background: 'aliceblue', padding: '8px' }}
//                                                 >
//                                                     {column.render('Header')}
//                                                 </th>
//                                             ))}
//                                         </tr>
//                                     ))}
//                                 </thead>
//                                 <tbody {...getTableBodyProps()}>
//                                     {rows.map(row => {
//                                         prepareRow(row);
//                                         return (
//                                             <tr {...row.getRowProps()}>

//                                                 {row.cells.map(cell => {
//                                                     return (
//                                                         <td {...cell.getCellProps()}
//                                                         // style={{ padding: '8px', borderBottom: 'solid 1px gray', background: 'papayawhip' }}
//                                                         >
//                                                             {cell.render('Cell')}
//                                                         </td>
//                                                     );
//                                                 })}
//                                             </tr>
//                                         );
//                                     })}
//                                 </tbody>
//                             </table>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </section>
//     )
// }

// export default ReactTable



import React, { useState } from 'react';
import { useTable, usePagination } from 'react-table';

const ReactTable = ({ columns, data }) => {
    const [pageIndexInput, setPageIndexInput] = useState(''); // Initialize as an empty string

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        gotoPage, // Function to navigate to a specific page
        pageCount,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: 10 },
        },
        usePagination
    );

    const handleGoToPage = () => {
        const pageNumber = parseInt(pageIndexInput) - 1; // Adjusting for zero-based index
        if (!isNaN(pageNumber) && pageNumber >= 0 && pageNumber < pageCount) {
            gotoPage(pageNumber);
        }
    };

    return (
        <section className="generate_code_section align-items-start">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className='request_table prompt_table overflow-auto'>
                            <table className='table' {...getTableProps()}>
                                <thead>
                                    {headerGroups.map(headerGroup => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map(column => (
                                                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                    {page.map(row => {
                                        prepareRow(row);
                                        return (
                                            <tr {...row.getRowProps()}>
                                                {row.cells.map(cell => {
                                                    return (
                                                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                                    );
                                                })}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                        {/* {/ Pagination controls /} */}
                        <div className='d-flex align-items-center justify-content-end gap-3 pagination'>
                            <div className='d-flex align-items-center justify-content-end gap-2 pagination_inner'>
                                <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                                    Previous
                                </button>{' '}
                                <button onClick={() => nextPage()} disabled={!canNextPage}>
                                    Next
                                </button>{' '}
                                <div className='d-flex align-items-center go_input gap-0'>
                                    <input
                                        type="number"
                                        value={pageIndexInput}
                                        // placeholder='0'
                                        onChange={e => setPageIndexInput(e.target.value)}
                                    />
                                    <button onClick={handleGoToPage} className='ms-0'>Go</button>
                                </div>
                                <span>
                                    Page{' '}
                                    <strong>
                                        {pageIndex + 1} of {pageCount}
                                    </strong>{' '}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ReactTable;
