import React from 'react'
import ForgotPassword from '../../../component/auth/ForgotPassword/forgotPassword'

const forgotPasswordPage = () => {
  return (
    <div>
        <ForgotPassword/>
    </div>
  )
}

export default forgotPasswordPage